<template>
    <div>
        <CRow>
            <CCol sm="12" xl="12">
                <CButton @click="collapse=!collapse" color="primary" class="mb-2 mr-2">
                    Filter
                </CButton>
                <CCollapse :show="collapse" :duration="400">
                    <CCard>
                        <CCardHeader>
                            <strong>Filter Options</strong>
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol sm="3">
                                    <CInput v-model="form.id.val" :description="form.id.inv" label="ID" />
                                </CCol>
                                <CCol sm="3">
                                    <CInput v-model="form.name.val" :description="form.name.inv" label="Name" />
                                </CCol>
                                <CCol sm="3">
                                    <CInput v-model="form.value.val" :description="form.value.inv" label="Data" />
                                </CCol>
                                <CCol sm="3">
                                    <label>Created at</label>
                                    <date-picker placeholder="Please Select" locale="en" v-model="form.created_at.val"></date-picker>
                                </CCol>
                            </CRow>
                            <br>
                            <div class="form-group form-actions">
                                <CButton @click="validator();filter=true;page=1;count();get();" color="primary" class="mr-2">Filter</CButton>
                                <CButton @click="remove_filter" color="danger">Remove Filter</CButton>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCollapse>
            </CCol>
            <CCol sm="12" xl="12">
                <CCard>
                    <CCardHeader>
                        <strong>Specific Data</strong>
                        <div class="card-header-actions">
                            Number of All Rows: {{row_count}}
                        </div>
                        </CCardHeader>
                    <CCardBody>
                        <CDataTable hover striped :items="items" :fields="fields" :noItemsView="{ noItems: 'No items to show.' }">
                            <template #action="data">
                                <td>
                                    <template>
                                    <div class="flex-container">
                                        <div v-show="$store.state.perm['arbitrage.SystemCustomValues'].put"
                                            @click="update_modal=true;id=data.item.id;get_specific_data()"
                                            v-c-tooltip.hover="'Quick Update'">
                                        <CIcon height="20" :content="$options.quick_update"/>
                                        </div>
                                        <div v-show="$store.state.perm['arbitrage.SystemCustomValues'].put"
                                            @click="redirect_to(`/data/custom/${data.item.id}/update`)"
                                            v-c-tooltip.hover="'Update'">
                                        <CIcon height="20" :content="$options.update"/>
                                        </div>
                                        <div v-show="$store.state.perm['arbitrage.SystemCustomValues'].delete"
                                            @click="delete_modal=true;id=data.item.id"
                                            v-c-tooltip.hover="'Delete'">
                                        <CIcon height="20" :content="$options.delete"/>
                                        </div>
                                    </div>
                                    </template>
                                </td>
                            </template>
                        </CDataTable>
                            <br>
                            <div> 
                                <CPagination
                                :activePage.sync="page"
                                :pages="page_count"
                                align="center"
                                :doubleArrows="true"
                                />
                            </div>
                        </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CModal
            title="Alert"
            size="sm"
            color="danger"
            :show.sync="delete_modal"
            >
            <p>Are you sure?</p>
            <template #footer>
                <CButton @click="delete_modal=false" color="secondary">No</CButton>
                <CButton @click="delete_row" color="danger">Yes</CButton>
            </template>
        </CModal>
        <CModal
            title="Quick Update Specific Data"
            size="sm"
            color="primary"
            :show.sync="update_modal"
            >
            <CForm @keyup.enter="validator();submit()">
                <CRow>
                    <CCol sm="12">
                        <CInput v-model="update_form.name.val" :description="update_form.name.inv" label="Name" />
                    </CCol>
                </CRow>
                <CRow>
                    <CCol sm="12">
                        <CInput v-model="update_form.value.val" :description="update_form.value.inv" label="Data" />
                    </CCol>
                </CRow>
                <br>
            </CForm>
            <template #footer>
                <CButton @click="validator();update_specific_data()" color="primary">Submit</CButton>
                <CButton @click="update_modal=false" color="secondary">Close</CButton>
            </template>
        </CModal>
    </div>
</template>
<script>
import { cilPencil, cilPenAlt, cilTrash } from '@coreui/icons'
export default {
    update: cilPencil, quick_update: cilPenAlt, delete: cilTrash,
    data () {
        return {
            row_count: '',
            items: [],
            fields: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Name' },
                { key: 'value', label: 'Value' },
                { key: 'created_at', label: 'Created at' },
                { key: 'action', label: 'Action' },
            ],
            collapse: false,
            filter: false,
            form: {
                id: {val: '', inv: '', v: { max: 1024 }},
                name: {val: '', inv: '', v: { max: 1024 }},
                value: {val: '', inv: '', v: { max: 1024 }},
                created_at: {val: '', inv: '', v: { max: 1024 }},
            },
            disable: false,
            page: 1,
            page_count: 0,
            offset: 0,
            delete_modal: false,
            id: 0,
            update_modal: false,
            update_form: {
                name: {val: '', inv: '', v: { req: true, min: 2, max: 1024 }},
                value: {val: '', inv: '', v: { req: true, max: 1024 }},
            },
        }
    },
    watch: {
        page: {
            handler(){
                this.count();
                this.get();
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
    },
    mounted(){
        this.count();
        this.get();
    },
    methods: {
        validator() {
            for (const key in this.form) {
                this.form[key].inv = '';
            }
            this.disable = false;
            for (const key in this.form) {
                for (const k in this.form[key].v) {
                    switch (k) {
                        case 'req':
                            if (this.form[key].val == '') {
                                this.form[key].inv = 'This field is required.';
                                this.disable = true;
                            }
                            break;
                        case 'max':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length > this.form[key].v[k]) {
                                    this.form[key].inv = `Must be a maximum of ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                    }
                }
            }
        },
        count() {
            if(this.disable == false) {
                let filter = '';
                if(this.filter){
                    for (const key in this.form) {
                        if(this.form[key].val != ''){
                            filter += `&${key}=` + this.form[key].val;
                        }
                    }
                }
                this.axios.get('arbitrage/system_custom_values/?count=1' + filter)
                    .then(res => {
                        this.row_count = String(res.data.count).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        if(res.data.count > 100){
                            this.page_count = Math.ceil(res.data.count / 100);
                        } else {
                            this.page_count = 1;
                        }
                    })
            }
        },
        get() {
            if (this.disable == false) {
                this.offset = 0;
                if(this.page != 1){
                    this.offset = (this.page * 100) - 99;
                }
                let filter = '';
                if(this.filter){
                    for (const key in this.form) {
                        if(this.form[key].val != ''){
                            filter += `&${key}=` + this.form[key].val;
                        }
                    }
                }
                this.axios.get('arbitrage/system_custom_values/' 
                    + '?limit=100' 
                    + '&offset=' + this.offset
                    + filter)
                    .then(res => {
                        res.data.map(val => {
                            val.created_at = this.$moment(val.created_at).format('YYYY-MM-DD HH:mm:ss');
                        });
                        this.items = res.data;
                    })
            }
        },
        delete_row() {
			this.axios.delete('arbitrage/system_custom_values/'
			+ '?id=' + this.id)
			.then(() => {
				this.$notify({
					group: 'foo',
					type: 'success',
					text: 'Deleted Successfully.'
                });
				this.delete_modal = false;
                this.count();
				this.get();
			})
			.catch(() => {
				this.$notify({
					group: 'foo',
					type: 'error',
					text: 'Error! please try again.'
                });
                this.delete_modal = false;
			})
        },
        redirect_to(route) {
            this.$router.push(route);
        },
        remove_filter() {
            for (const key in this.form) {
                this.form[key].val = '';
                this.form[key].inv = '';
            }
            this.disable = false;
            this.filter = false;
            this.page = 1;
            this.count();
            this.get();
        },
        get_specific_data(){
            this.axios.get('arbitrage/system_custom_values/'
                + '?id=' + this.id)
                .then(res => {
                    for(const key in res.data[0]){
                        for(const k in this.update_form){
                            if(key == k){
                                this.update_form[k].val = res.data[0][key];
                            }
                        }
                    }
                }) 
        },
        update_specific_data(){
            if(this.disable == false){
                const data = new FormData();
                data.set('ids', JSON.stringify([parseInt(this.id)]));
                for (const key in this.update_form){
                    data.set(key, this.update_form[key].val);
                }
                this.axios.put('arbitrage/system_custom_values/', data)
                    .then(() => {
                        this.update_modal = false;
                        this.count();
                        this.get();
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            text: 'Updated Successfully.'
                        });
                        for (const key in this.update_form){
                            this.update_form[key].val = '';
                        }
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            text: 'Error! please try again.'
                        });
                    })
            }
        },
    },
}
</script>
